
body {
  margin: 0;
   /* background-color: #001f3f; */
  background-image: linear-gradient(to left, rgb(0, 31, 63), rgb(11, 4, 33));
  /* background-size: cover; */
  font-family: 'Segoe UI', Verdana, Geneva, Tahoma, sans-serif !important;
}

 .navLink {
  color: azure;
  font-size: 100%;
}
/*
@media (max-width: 500px) {
  .navLink {
    color: blue !important;
    padding: 0px 0px !important;
  }
} */

/* .nav-link {
  padding: .8rem 0rem !important;
  color: azure !important;
  font-size: 12px;
  width: min-content !important;
} */

.h1Wrapper {
  width: max-content;
  margin: 0 auto;
}

h1 {
  text-align: center; 
  width: max-content;
  margin-bottom: 50em;
  color: azure;
}

@media (max-width: 500px) {
  h1 {
    font-size: 5.5vw !important;
  }
  h2 {
    font-size: 4.5vw !important;
  }
  h3 {
    font-size: 4vw !important;
  }
}

.typeWriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid rgb(110, 192, 138); /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  color: azure;
  animation: 
    typing 1.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: rgb(110, 192, 138); }
}

h2 {
  text-align: center;
  width: max-content;
  /* margin-bottom: 50em; */
  color: azure;
}

p {
  padding-top: 10%;
  padding-bottom: 5%;
  font-size: large;
  color: azure;
}

.page {
  margin-top: 5%;
  padding-left: 5%;
  padding-right: 5%;
}

.workExperiencePage {
  margin-top: 5%;
}

/* .Card {
  size:500px
} */

.icons {
  font-size: 12vw;
}
.icons-small {
  font-size: 6vw;
}

@media (min-width: 1500px) {
  .icons {
    font-size: 200px;
  }
  .icons-small {
    font-size: 100px;
  }
}


.table-text {
  font-size: 1.2em;
  text-align: center;
  color: azure;
  padding-top: 2.5em;
}

.cert-card {
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  border: 1px solid rgb(255, 255, 255);
  border-radius: 2.5%;
  /* background-color: rgb(11, 45, 80); */
  background-color: #92A8D1;
  padding: 2%;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
  justify-self: center;
}

.link-text {
  color: black;
  font-size: 1em;
  text-decoration: none;
  text-align: center;
}
.link-text:hover {
  color: black;
  text-decoration: underline;
  position: bottom;
}

.timeline {
  margin-top: 1%;
  padding-left: 0px  !important;
  padding-right: 0vw !important;
}

.personalProjectRow {
  margin-bottom: var(--bs-gutter-y) !important;
}

.personalProjectText {
  padding-top: 0.5rem;
  padding-bottom: 2rem;
}
.personalProjectCard {
  padding-top: calc(var(--bs-gutter-x) * .5);
  position: absolute;
  background-color: #92A8D1 !important;
  /* padding: 10px; */
  /* border-width: 10px !important;
  border-style: solid !important;
  border-color: transparent !important; */
}
.personalProjectButton {
  position: absolute;
  padding: 1rem;
  bottom: 3.5rem;
}

.contactIcon {
  /* font-size: 4vw; */
  text-align: center;
  text-decoration: none;
  color: azure;
}
@media (min-width: 768px) {
  .contactIcon {
     font-size: 36px;
    text-align: center;
    text-decoration: none;
    color: azure;
  }
}

hr.solid {
  border-top: 3px solid #bbb;
  margin-top: 9vw;
  /* position: absolute; */
  bottom: 10px;
}

.footer {
  height: 5vw;
  margin-top: 1vw;
  /* position: fixed; */
  /* bottom: 0; */
  /* left: 42%; */
  text-align: center;
}

.goodreads > * {
  margin-top: 30px;
  color: azure !important;
}
@media (min-width: 768px) {
  .goodreads > * {
    margin-top: 30px;
    color: azure;
  }
}

.strava {
  color: azure;
  margin-top: 50px;
  position: relative;
}
.stravaWidget {
  display: grid;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}